import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  SampleButton,
  Text,
} from "../components";

import {
  Details,
  Layout,
  NavTile,
  Overlay,
  Player,
  PlayerContextProvider,
  SampleOverlay,
  SongTile,
} from "../patterns";

import { formatName } from "../utils/format-name";
import { selectHighlight, selectSampleList } from "../selectors";

const Home = ({ data }) => {
  const [nowPlaying, setNowPlaying] = useState([]);
  const [sampleOverlay, setSampleOverlay] = useState(false);

  const pageContent = data.contentfulPage;
  const collections = data.allContentfulCollection.nodes;
  const highlights = selectHighlight(data.contentfulHighlights);
  const sampleList = selectSampleList(data.contentfulSampleList);

  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  }, []);

  const handlePlayerClose = () => {
    setNowPlaying({});
  };

  return (
    <PlayerContextProvider value={{ nowPlaying }}>
      <Layout>
        <Box
          $bgColor="background.dark"
          $marginBottom={8}
          $paddingY={8}
          $width="100%"
        >
          <Container>
            <Box $paddingX={5}>
              <Flex $flexDirection="column" $gap={5}>
                <Text
                  as="h1"
                  $fontSize="heading3"
                  $fontFamily="heading"
                  $fontWeight="900"
                  $maxWidth="840px"
                  $textTransform="uppercase"
                >
                  {pageContent.subHeading}
                </Text>

                <Text as="p" $maxWidth="640px">
                  {pageContent.bodyText.bodyText}
                </Text>

                <Box $marginBottom={{ xs: 5, md: 6 }}>
                  <Box $marginBottom={3}>
                    <Text
                      as="a"
                      $fontSize="body"
                      $fontWeight="bold"
                      href="mailto:info@greenwichmusic.co.uk"
                    >
                      info@greenwichmusic.co.uk
                    </Text>
                  </Box>

                  <Box>
                    <Text
                      as="a"
                      $fontSize="body"
                      $fontWeight="bold"
                      href="tel:+447940441487"
                    >
                      +44 (0) 7940 441 487
                    </Text>
                  </Box>
                </Box>

                <Box>
                  <Grid
                    $gap={5}
                    $gridTemplateColumns={{ xs: "1fr", lg: "repeat(3, 1fr)" }}
                  >
                    {collections.map((collection) => (
                      <NavTile
                        as="a"
                        heading={collection.heading}
                        subHeading={collection.subHeading}
                        href={`/collections/${collection.slug}`}
                        $counter={collection.order}
                      />
                    ))}
                  </Grid>
                </Box>
              </Flex>
            </Box>
          </Container>
        </Box>

        <Container $paddingX={5} $marginBottom={8}>
          <Text
            as="h2"
            $fontSize="heading3"
            $fontFamily="heading"
            $fontWeight="900"
            $textAlign="center"
            $textTransform="uppercase"
            $marginBottom={6}
          >
            {sampleList.title}
          </Text>

          <Grid
            $gap={6}
            $gridTemplateColumns={{
              xs: "1fr",
              md: "1fr 1fr",
              lg: "repeat(3, 1fr)",
            }}
          >
            {sampleList &&
              sampleList.samples.map((sample) => (
                <Box>
                  <SampleButton
                    onClick={() => {
                      setSampleOverlay(sample);
                      setNowPlaying({});
                    }}
                  >
                    <Image src={sample.thumbnail} $width="100%" />
                  </SampleButton>

                  <Text
                    as="p"
                    $fontFamily="heading"
                    $fontWeight="600"
                    $marginBottom={1}
                  >
                    {sample.title}
                  </Text>

                  {sample.featuring ? (
                    <Details
                      underscoreTitle={sample.featuring.heading}
                      composer={sample.featuring.composer}
                      catalogueNumber={sample.featuring.catalogueNumber}
                    />
                  ) : (
                    <Text
                      as="p"
                      $fontFamily="heading"
                      $fontSize="bodySm"
                      $marginBottom={1}
                    >
                      Coming soon
                    </Text>
                  )}
                </Box>
              ))}
          </Grid>
        </Container>

        <Container $size="sm" $marginBottom={9}>
          <Text
            as="h2"
            $fontSize="heading3"
            $fontFamily="heading"
            $fontWeight="900"
            $marginBottom={6}
            $textAlign="center"
            $textTransform="uppercase"
          >
            {highlights.heading}
          </Text>
          {highlights.items.map((item, i) => (
            <Flex $flexDirection="column" $marginBottom={5}>
              <SongTile
                key={`item-${i}`}
                id={formatName(item.heading)}
                $selected={
                  nowPlaying &&
                  nowPlaying.catalogueNumber === item.catalogueNumber
                }
                $counter={i + 1}
              >
                <SongTile.Content>
                  <Text
                    as="p"
                    $fontSize="heading4"
                    $fontFamily="heading"
                    $fontWeight="900"
                    $textTransform="uppercase"
                    $marginBottom={2}
                  >
                    {item.heading}
                  </Text>
                  <Details
                    composer={item.composer}
                    publisher={item.publisher}
                    catalogueNumber={item.catalogueNumber}
                    $marginBottom={4}
                  />
                </SongTile.Content>
                <SongTile.Play
                  onClick={() => setNowPlaying(item)}
                  title={`Play ${item.heading}`}
                />
                <SongTile.Purchase
                  href={`tracks/${item.catalogueNumber}`}
                  title={`Purchase ${item.heading}`}
                >
                  Purchase
                </SongTile.Purchase>
              </SongTile>
              {item.collection && (
                <Button
                  as="a"
                  href={`/collections/${item.collection.slug}`}
                  $marginBottom={6}
                >
                  View more {item.collection.heading}
                </Button>
              )}
            </Flex>
          ))}
        </Container>
        <Player {...nowPlaying} handleClose={handlePlayerClose} />
      </Layout>

      {sampleOverlay && (
        <Overlay
          onClose={() => setSampleOverlay(false)}
          $width="80%"
          $maxWidth="1260px"
        >
          <SampleOverlay {...sampleOverlay} />
        </Overlay>
      )}
    </PlayerContextProvider>
  );
};

export default Home;

export const homeQuery = graphql`
  query HomeQuery {
    contentfulPage(key: { eq: "home" }) {
      heading
      subHeading
      bodyText {
        bodyText
      }
    }
    allContentfulCollection(sort: { fields: order }) {
      nodes {
        heading
        slug
        subHeading
        order
      }
    }
    contentfulSampleList(key: { eq: "Home" }) {
      title
      samples {
        title
        thumbnail {
          fluid(maxWidth: 460) {
            src
          }
        }
        src
        featuring {
          catalogueNumber
          composer
          heading
          publisher
        }
      }
    }
    contentfulHighlights(key: { eq: "homepage" }) {
      heading
      items {
        heading
        publisher
        source {
          file {
            url
            fileName
            contentType
          }
        }
        composer
        catalogueNumber
        category {
          collection {
            slug
            heading
          }
        }
      }
    }
  }
`;
